// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/atoms/Text.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/atoms/Text.tsx");
  import.meta.hot.lastModified = "1726176605631.3848";
}
// REMIX HMR END

import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import { forwardRef, useCallback, useMemo, useRef } from 'react';
import { textIcons } from '@/constants';
import { isPrimitive } from '@/guards';
import { cn } from '~/utils/cn';
import { Tooltip } from './Tooltip';
export const baseTextVariants = {
  variants: {
    variant: {
      primary: 'text-primary',
      error: 'text-destructive',
      success: 'text-success',
      // ✅ secondary inline with Figma
      secondary: 'text-secondary',
      muted: 'text-gray-500',
      link: 'hover:underline text-primary',
      // ✅ subtle inline with Figma
      subtle: 'text-subtle'
    },
    size: {
      'xs': 'text-xs',
      'sm': 'text-sm',
      'base': 'text-base',
      'lg': 'text-lg',
      'xl': 'text-xl',
      '2xl': 'text-2xl',
      '3xl': 'text-3xl',
      '4xl': 'text-4xl',
      '5xl': 'text-5xl',
      '6xl': 'text-6xl',
      '7xl': 'text-7xl',
      '8xl': 'text-8xl',
      '9xl': 'text-9xl'
    },
    weight: {
      // TODO: remove number weights. These would make changing the atom's weight values more difficult
      '100': 'font-thin',
      '200': 'font-extralight',
      '300': 'font-light',
      '400': 'font-normal',
      '500': 'font-medium',
      '600': 'font-semibold',
      '700': 'font-bold',
      '800': 'font-extrabold',
      '900': 'font-black',
      'thin': 'font-thin',
      'extralight': 'font-extralight',
      'light': 'font-light',
      'normal': 'font-normal',
      'medium': 'font-medium',
      'semibold': 'font-semibold',
      'bold': 'font-bold',
      'extrabold': 'font-extrabold',
      'black': 'font-black'
    }
  },
  defaultVariants: {
    variant: 'primary',
    size: 'sm',
    weight: 'normal'
  }
};
const textVariants = cva('max-w-full', baseTextVariants);
const lineClamps = {
  1: 'line-clamp-1',
  2: 'line-clamp-2',
  3: 'line-clamp-3',
  4: 'line-clamp-4',
  5: 'line-clamp-5',
  6: 'line-clamp-6'
};
const TextMain = _s(forwardRef(_c = _s((props, ref) => {
  _s();
  const {
    className,
    children,
    variant,
    tooltipIfOverflow = true,
    lineClamp = null,
    // TODO: update Text atoms to not truncate by default
    truncate = lineClamp == null,
    // default to truncating = true if lineClamp is not set.
    asChild = false,
    size,
    weight,
    ...rest
  } = props;
  const Comp = asChild ? Slot : 'span';
  const triggerRef = useRef(null);
  const renderTextEl = useCallback(() => <Comp className={cn(truncate && 'truncate', lineClamp == null && 'inline-block', lineClamp != null && lineClamps[lineClamp], textVariants({
    variant,
    size,
    weight
  }), className)} ref={ref} {...rest}>
        {children}
      </Comp>, [Comp, children, className, size, weight, ref, rest, truncate, variant, lineClamp]);
  const onlyShowIfOverflow = useMemo(() => ({
    horizontal: true,
    vertical: lineClamp != null
  }), [lineClamp]);
  return tooltipIfOverflow ? <Tooltip onlyShowIfOverflow={onlyShowIfOverflow} triggerRef={triggerRef} open>
      <Tooltip.Trigger asChild ref={triggerRef}>
        {renderTextEl()}
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content className='max-h-[25vh] max-w-[50vw] overflow-auto break-words'>
          {isPrimitive(children) ? <p>{children}</p> : children}
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip> : renderTextEl();
}, "U8e4ugSflkcJoGW+EeaUNwsSrqk=")), "U8e4ugSflkcJoGW+EeaUNwsSrqk=");
_c2 = TextMain;
TextMain.displayName = 'Text';
const EditableText = forwardRef(_c3 = ({
  isEditing,
  editElement,
  children,
  ...props
}, ref) => {
  return isEditing ? editElement : <TextMain ref={ref} {...props}>
        {children}
      </TextMain>;
});
_c4 = EditableText;
EditableText.displayName = 'EditableText';
const TextIcon = forwardRef(_c5 = ({
  icon,
  className,
  bordered = false,
  ...props
}, ref) => {
  return <TextMain tooltipIfOverflow={false} className={cn('inline-block min-w-min items-center justify-center text-center', bordered && 'rounded border border-gray-200 bg-gray-100 px-1 py-0.5', className)} {...props} ref={ref}>
        {textIcons[icon]}
      </TextMain>;
});
_c6 = TextIcon;
TextIcon.displayName = 'TextIcon';
export const Text = Object.assign(TextMain, {
  Editable: EditableText,
  Icon: TextIcon
});
_c7 = Text;
var _c, _c2, _c3, _c4, _c5, _c6, _c7;
$RefreshReg$(_c, "TextMain$forwardRef");
$RefreshReg$(_c2, "TextMain");
$RefreshReg$(_c3, "EditableText$forwardRef");
$RefreshReg$(_c4, "EditableText");
$RefreshReg$(_c5, "TextIcon$forwardRef");
$RefreshReg$(_c6, "TextIcon");
$RefreshReg$(_c7, "Text");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;